define(['coreUtils'],
    function (/** layout.proxyLayoutRegistrar */ coreUtils) {
        'use strict';
        const proxyLayoutRegistrar = coreUtils.proxyLayoutRegistrar;
        function measureButton(proxyNode, siteData, measureMap) {
            const compId = proxyNode.id;

            measureMap.width[compId] = proxyNode.offsetWidth;
            measureMap.height[compId] = proxyNode.offsetHeight;

            return {
                comp: {
                    compType: 'wysiwyg.viewer.components.SiteButton',
                    compId,
                    structureInfo: {
                        dataItem: null,
                        propertiesItem: null,
                        layout: null,
                        styleItem: null
                    }
                },
                domManipulations: []
            };
        }


        proxyLayoutRegistrar.registerCustomMeasure('Button', measureButton);
        proxyLayoutRegistrar.registerCustomMeasure('Button2', measureButton);
    });
