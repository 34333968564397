define(['zepto', 'lodash', 'coreUtils'], function ($, _, /** layout.proxyLayoutRegistrar */ coreUtils) {
    'use strict';

    const proxyLayoutRegistrar = coreUtils.proxyLayoutRegistrar;
    function measureNode(compNode, measureMap, compId) {
        const itemsContainerId = `${compId}itemsContainer`;

        measureMap.height[itemsContainerId] = compNode.offsetHeight;
        measureMap.width[itemsContainerId] = compNode.offsetWidth;

        measureMap.height[compId] = compNode.offsetHeight;
        measureMap.width[compId] = compNode.offsetWidth;
    }

    function measure(compNode, siteData, measureMap) {
        const itemsContainerId = `${compNode.id}itemsContainer`;
        const comp = $(`#${itemsContainerId}`);
        const $compNode = $(compNode);

        const columns = Number($compNode.attr('data-total-columns'));
        const rows = Number($compNode.attr('data-total-rows'));
        const gap = Number($compNode.attr('data-gap'));
        const autoscale = $compNode.data('autoscale');

        let domManipulations = [];

        const children = comp.children();

        if (children.length) {
            const child = children[0];
            const width = child.offsetWidth;
            const height = child.offsetHeight;

            domManipulations = _.map(children, function (childNode) {
                const index = parseInt($(childNode).attr('data-index'), 10);

                const row = Math.floor(index / columns) % rows;
                const topGap = row * gap;

                const col = index % columns;
                const leftGap = col * gap;

                return {
                    node: childNode,
                    funcName: 'css',
                    params: {
                        top: row * height + topGap, // eslint-disable-line no-mixed-operators
                        left: col * width + leftGap // eslint-disable-line no-mixed-operators
                    }
                };
            });
        }

        const nodeToMeasure = autoscale ? compNode.parentNode : compNode;
        measureNode(nodeToMeasure, measureMap, compNode.id);


        return {
            domManipulations
        };
    }

    proxyLayoutRegistrar.registerCustomMeasure('GalleryProxy', measure);
});
