define(['coreUtils'], function (/** layout.proxyLayoutRegistrar */ coreUtils) {
    'use strict';

    const proxyLayoutRegistrar = coreUtils.proxyLayoutRegistrar;
    proxyLayoutRegistrar.registerCustomMeasure('MediaLabel', function (proxyNode, siteData, measureMap) {
        const compId = proxyNode.id;

        measureMap.width[compId] = proxyNode.offsetWidth;
        measureMap.height[compId] = proxyNode.offsetHeight;

        return {
            comp: {
                compType: 'wysiwyg.viewer.components.MediaRichText',
                compId,
                structureInfo: {
                    dataItem: null,
                    propertiesItem: null,
                    layout: null,
                    styleItem: null
                }
            },
            domManipulations: []
        };
    });
});
