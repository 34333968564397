define(['lodash', 'warmupUtils', 'layout', 'warmupUtilsLib'], function (_, warmupUtils, layoutPackage, warmupUtilsLib) {
    'use strict';

    const rootLayoutUtils = layoutPackage.rootLayoutUtils;
    const siteUtilsLayout = warmupUtils.layoutUtils;
    const unitize = warmupUtilsLib.style.unitize;

    const classBasedPatchers = {};
    const classBasedCustomMeasures = {};
    const classBasedShouldMeasureDOM = {};
    const classBasedMeasureChildren = {};

    const classBasedAdditionalMeasure = {};

    function isCompRenderedInFixedPosition(style) {
        return style.position === 'fixed';
    }

    function shouldOnlyMeasureDomWidth(structure) {
        return siteUtilsLayout.isHorizontallyStretched(structure.layout);
    }

    function shouldClassBasedMeasureDomWidth(structure) {
        return classBasedShouldMeasureDOM[structure.componentType];
    }

    function shouldOnlyMeasureDomHeight(structure) {
        return siteUtilsLayout.isVerticallyStretched(structure.layout);
    }

    function shouldClassBasedMeasureDomHeight(structure) {
        return classBasedShouldMeasureDOM[structure.componentType];
    }

    function measureComponentZIndex(measureMap, compId, style) {
        let zIndex = style.zIndex;
        if (zIndex !== 'auto') {
            zIndex = parseFloat(zIndex);
            if (!isNaN(zIndex)) {
                measureMap.zIndex[compId] = zIndex;
            }
        }
    }

    function measurePositionForFixedComp(measureMap, compId, domNode, style) {
        if (isCompRenderedInFixedPosition(style)) {
            measureMap.fixed[compId] = true;
            measureMap.top[compId] = domNode.offsetTop;
            measureMap.left[compId] = domNode.offsetLeft;
        }
    }

    function measureComponentWidth(measureMap, compId, domNode, structure) {
        const structureWidth = _.get(structure, 'layout.width', 0);

        if (shouldOnlyMeasureDomWidth(structure)) {
            measureMap.width[compId] = domNode.offsetWidth;
        } else if (shouldClassBasedMeasureDomWidth(structure) || !_.get(structure, 'layout.width')) {
            measureMap.width[compId] = Math.max(domNode.offsetWidth, structureWidth);
        } else {
            measureMap.width[compId] = structureWidth;
        }
    }

    function measureComponentHeight(measureMap, compId, domNode, structure) {
        let minHeight = _.get(structure, 'layout.height', 0);
        const aspectRatio = _.get(structure, 'layout.aspectRatio', 0);
        if (aspectRatio) {
            minHeight = aspectRatio * measureMap.width[compId]; //aspect ratio is a minimum height, domNode height should override for a dynamically resizing component
        }
        if (shouldOnlyMeasureDomHeight(structure)) {
            measureMap.height[compId] = domNode.offsetHeight;
        } else if (shouldClassBasedMeasureDomHeight(structure) || !_.get(structure, 'layout.height')) {
            measureMap.height[compId] = Math.max(domNode.offsetHeight, minHeight);
        } else {
            measureMap.height[compId] = minHeight;
        }
    }

    function measureDeadComp(measureMap, compId, domNode, structure) {
        const structureWidth = _.get(structure, 'layout.width', 0);
        const structureHeight = _.get(structure, 'layout.height', 0);
        measureMap.width[compId] = structureWidth;
        measureMap.height[compId] = structureHeight;
    }

    function getLeft(structure, domNode) {
        const offsetLeft = domNode.offsetLeft;
        const x = _.get(structure, 'layout.x', 0);
        const diff = Math.abs(x - offsetLeft);

        return diff === 0.5 ? x : offsetLeft;
    }

    function updateStructureCompMeasures(compId, domNode, structure, measureMap) {
        if (measureMap.isDeadComp[compId]) {
            measureDeadComp(measureMap, compId, domNode, structure);
            return;
        }
        const computedStyle = window.getComputedStyle(domNode);
        measurePositionForFixedComp(measureMap, compId, domNode, computedStyle);
        measureComponentZIndex(measureMap, compId, computedStyle);
        measureComponentWidth(measureMap, compId, domNode, structure);
        measureComponentHeight(measureMap, compId, domNode, structure);

        measureMap.left[compId] = getLeft(structure, domNode);
    }

    function isComponentDead(domNode) {
        return domNode.getAttribute('data-dead-comp');
    }

    function getDomNode(compId, getDomNodeFunc) {
        if (!compId) {
            return false;
        }
        const domNode = getDomNodeFunc(compId);
        if (!domNode) {
            return false;
        }
        return domNode;
    }

    function measureComponent(structure, structureInfo, getDomNodeFunc, measureMap, nodesMap, siteData) {
        const compId = structureInfo.id;
        const domNode = getDomNode(compId, getDomNodeFunc);
        if (!domNode) {
            return;
        }
        nodesMap[compId] = domNode;
        updateStructureCompMeasures(compId, domNode, structure, measureMap);

        const isDeadComp = isComponentDead(domNode);
        if (isDeadComp) {
            measureMap.isDeadComp[compId] = true;
            return;
        }

        if (classBasedCustomMeasures[structureInfo.type]) {
            classBasedCustomMeasures[structureInfo.type](compId, measureMap, nodesMap, structureInfo, siteData);
        }

        if (classBasedAdditionalMeasure[structureInfo.type]) {
            classBasedAdditionalMeasure[structureInfo.type](compId, measureMap, nodesMap, siteData, structureInfo);
        }
    }

    function measureComponentChildren(structureInfo, getDomNodeFunc, measureMap, nodesMap, siteData) {
        const compId = structureInfo.id;
        const domNode = getDomNode(compId, getDomNodeFunc);
        if (!domNode || isComponentDead(domNode)) {
            return;
        }

        if (classBasedMeasureChildren[structureInfo.type]) {
            let childrenSelectors = classBasedMeasureChildren[structureInfo.type];
            if (typeof childrenSelectors === 'function') {
                childrenSelectors = childrenSelectors(compId, nodesMap, structureInfo, siteData);
            }
            _.forEach(childrenSelectors, function (selector) {// eslint-disable-line complexity
                const isChildComponent = _.isPlainObject(selector);
                const childIdPath = isChildComponent ? selector.pathArray : selector;
                const childNode = getDomNodeFunc.apply(undefined, [compId].concat(childIdPath)) || getDomNodeFunc.apply(undefined, [compId, 'component'].concat(childIdPath));
                if (childNode) {
                    const childIdString = childIdPath.join('');
                    const childId = compId + childIdString;
                    nodesMap[childId] = childNode;
                    measureMap.height[childId] = childNode.offsetHeight;
                    measureMap.width[childId] = childNode.offsetWidth;

                    if (isChildComponent && classBasedCustomMeasures[selector.type]) {
                        classBasedCustomMeasures[selector.type](childId, measureMap, nodesMap, structureInfo, siteData);
                    }
                }
            });
        }
    }

    function patchNodeDefault(id, patch, measureMap, layout) {
        const style = {};

        if (layout) {
            if (!siteUtilsLayout.isVerticallyDocked(layout) || siteUtilsLayout.isVerticallyStretchedToScreen(layout)) {
                style.top = unitize(measureMap.top[id]);
            }
            if (!siteUtilsLayout.isVerticallyStretched(layout) || siteUtilsLayout.isVerticallyStretchedToScreen(layout)) {
                style.height = unitize(measureMap.height[id]);
            }
            if (!_.isEmpty(style)) {
                patch.css(id, style);
            }
        }
    }

    function patchScreenWidthComponents(id, patchers, measureMap, structureInfo, siteData) {
        const layout = structureInfo.layout;
        if (layout && warmupUtils.dockUtils.isHorizontalDockToScreen(layout)) {
            const rootWidth = rootLayoutUtils.getRootWidth(siteData.getSiteWidth(), measureMap, structureInfo.rootId);
            const screenOffsetFromRoot = 0 - rootLayoutUtils.getRootLeft(siteData, measureMap, structureInfo.rootId);
            const style = warmupUtils.dockUtils.getDockedStyleWithMargins(layout, siteData.getPageMargins(), siteData.getScreenWidth(), rootWidth, screenOffsetFromRoot);
            const verticallyStyleProperties = _.pick(style, ['left', 'width']);
            measureMap.left[id] = parseInt(verticallyStyleProperties.left, 10); //only ok since style.left is always in px only for horizontalDockToScreen
            patchers.css(id, verticallyStyleProperties); //style for screenWidth will have both left and width
        }
    }

    function patchComponent(structureInfo, patchers, nodesMap, measureMap, siteData) {
        const id = structureInfo.id;
        const layout = structureInfo.layout;
        patchNodeDefault(id, patchers, measureMap, layout);
        patchScreenWidthComponents(id, patchers, measureMap, structureInfo, siteData);

        const isDeadComp = measureMap.isDeadComp[id];

        if (!isDeadComp && classBasedPatchers[structureInfo.type]) {
            classBasedPatchers[structureInfo.type](id, patchers, measureMap, structureInfo, siteData);
        }
    }

    function patchWithoutPositioning(structureInfo, patchers, measureMap, siteData) {
        const id = structureInfo.id;
        const isDeadComp = measureMap.isDeadComp[id];

        if (!isDeadComp && classBasedPatchers[structureInfo.type]) {
            classBasedPatchers[structureInfo.type](id, patchers, measureMap, structureInfo, siteData);
        }
    }

    return {
        patchWithoutPositioning,
        patchComponent,
        measureComponent,
        measureComponentChildren,
        isComponentDead,

        /**
         * Allows to plugin a patching method to component that needs it
         * @param {string} className The component class name
         * @param {function(string, Object.<string, Element>,  layout.measureMap, layout.structureInfo, core.SiteData)} patcher The patching method
         */
        registerPatcher(className, patcher) {
            classBasedPatchers[className] = patcher;
        },

        /**
         * @param {string} className
         * @param {function(string, Object.<string, Element>, layout.measureMap, layout.structureInfo, core.SiteData)[]}patchersArray
         */
        registerPatchers(className, patchersArray) {
            classBasedPatchers[className] = function () {
                const args = arguments;
                _.forEach(patchersArray, function (patcher) {
                    patcher.apply(null, args);
                });
            };
        },

        /**
         * the fix will run after the measure but before enforce anchors.
         * use this if you need to update the comp size according to some inner element size (example in site-button)
         * @param {String} className
         * @param {function(string, layout.measureMap, Object.<string, Element>, core.SiteData, layout.structureInfo)} fix
         * a function that runs during measure, you can change only the measure map there
         */
        registerCustomMeasure(className, fix) {
            classBasedCustomMeasures[className] = fix;
        },

        /**
         * Allows to request to be measured during layout
         * @param className The component class name
         */
        registerRequestToMeasureDom(className) {
            classBasedShouldMeasureDOM[className] = true;
        },

        /**
         * Allows to request to measure children during layout
         * @param className The component class name
         * @param {(Array.<String|{pathArray: Array.<String>, type: string}>|function|)} pathArray An array of children paths (array of strings) to be measured.
         *  This can also be a callback method that returns the pathArray
         */
        registerRequestToMeasureChildren(className, pathArray) {
            classBasedMeasureChildren[className] = pathArray;
        },

        registerAdditionalMeasureFunction(className, measureFunction) {
            classBasedAdditionalMeasure[className] = measureFunction;
        },

        maps: {
            classBasedMeasureChildren,
            classBasedCustomMeasures,
            classBasedPatchers
        }
    };
});
