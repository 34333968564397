define(['zepto', 'lodash', 'coreUtils'], function ($, _, coreUtils) {
    'use strict';

    const proxyLayoutRegistrar = coreUtils.proxyLayoutRegistrar;

    function measureImage(proxyNode, siteData, measureMap) {
        const $node = $(proxyNode);
        const compNode = proxyNode.firstChild;
        const compId = compNode.id;

        const dataItem = {
            width: parseInt($node.attr('data-width'), 10),
            height: parseInt($node.attr('data-height'), 10),
            uri: $node.attr('data-uri'),
            quality: siteData.getGlobalImageQuality()
        };

        const propertiesItem = {
            displayMode: $node.attr('data-display-mode')
        };

        measureMap.width[compId] = proxyNode.offsetWidth;
        measureMap.height[compId] = proxyNode.offsetHeight;
        measureMap.custom[compId] = {thisIsMyHeight: measureMap.height[compId]};

        return {
            comp: {
                compType: 'wysiwyg.viewer.components.WPhoto',
                compId,
                structureInfo: {
                    dataItem,
                    propertiesItem,
                    layout: null,
                    styleItem: null
                }
            },
            domManipulations: []
        };
    }

    proxyLayoutRegistrar.registerCustomMeasure('Image', measureImage);
});
