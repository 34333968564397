define(['zepto', 'lodash', 'coreUtils', 'wixappsLayout/proxies/proxyLayout/util/masonryCalculations'], function ($, _, /** layout.proxyLayoutRegistrar */ coreUtils, /** layout.masonryCalculations */ masonryCalculations) {
    'use strict';

    const proxyLayoutRegistrar = coreUtils.proxyLayoutRegistrar;
    function getProxyProperties(proxyNode) {
        const columns = parseInt($(proxyNode).attr('data-columns'), 10);
        const direction = $(proxyNode).attr('data-direction');
        const horizontalGap = parseInt($(proxyNode).attr('data-horizontal-gap'), 10);
        const verticalGap = parseInt($(proxyNode).attr('data-vertical-gap'), 10);

        return {
            columns,
            direction,
            horizontalGap,
            verticalGap
        };
    }

    function getGalleryChildrenHeights(proxyNode) {
        return _.map(proxyNode.childNodes, 'clientHeight');
    }

    function measurePaginatedColumnGalleryProxy(proxyNode) {
        const galleryChildrenHeights = getGalleryChildrenHeights(proxyNode);
        const properties = getProxyProperties(proxyNode);

        const proxyChildren = proxyNode.childNodes;
        const rowsAndColsData = masonryCalculations.getMasonryRowsAndColumns(galleryChildrenHeights, properties.columns);
        const columnWidth = Math.floor(proxyNode.clientWidth / properties.columns);

        const result = {
            domManipulations: [],
            needsRelayout: true //needs to do 2 cycles of layout
        };
        const manipulations = result.domManipulations;

        const newTops = [];

        _.forEach(proxyChildren, function (node, i) {
            const position = rowsAndColsData[i];
            const top = position.row * properties.verticalGap + position.topOffset; // eslint-disable-line no-mixed-operators
            const sidePadding = masonryCalculations.getColumnSidePadding(position.col, properties.columns, properties.horizontalGap, properties.direction);
            const sideOffset = position.col * columnWidth;

            newTops.push(top);
            const cssParams = {
                position: 'absolute',
                top: `${top}px`,
                'padding-right': `${sidePadding.right}px`,
                'padding-left': `${sidePadding.left}px`
            };

            if (properties.direction === 'rtl') {
                cssParams.right = `${sideOffset}px`;
            } else {
                cssParams.left = `${sideOffset}px`;
            }

            manipulations[i] = {
                node,
                funcName: 'css',
                params: cssParams
            };
        });

        const height = _(proxyChildren)
            .map(function (node, i) {
                return newTops[i] + galleryChildrenHeights[i];
            })
            .max();

        //patch width and height of the proxyNode itself
        manipulations.push({
            node: proxyNode,
            funcName: 'css',
            params: {
                height: `${height}px`
            }
        });

        return result;
    }

    proxyLayoutRegistrar.registerCustomMeasure('PaginatedColumnGalleryProxy', measurePaginatedColumnGalleryProxy);
});
