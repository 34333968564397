define([
    'zepto',
    'lodash',
    'layout',
    'warmupUtils'
], function (
    $,
    _,
    layout,
    warmupUtils
) {
    'use strict';
    const {balataLayout, screenWidthContainerLayout} = layout.specificComponents;

    const balataConsts = warmupUtils.mediaConsts.balataConsts;

    const POPUP_OVERLAY_SKIN_PART_ID = balataConsts.BALATA;

    function measurePage(id, measureMap, nodesMap, serializedComp, {isMobileView, pageMinHeight}) {
        // measureMap.height[id] = nodesMap[id].offsetHeight;
        screenWidthContainerLayout.measureScreenWidthContainer(id, measureMap, nodesMap);
        measureMap.shrinkableContainer[id] = true;

        const pageBottomByComponents = warmupUtils.layoutUtils.getPageBottomChildEnd(measureMap, nodesMap, isMobileView(), serializedComp) + measureMap.containerHeightMargin[id]; //IMPORTANT! the containerHeightMargin must be here or we will have an endless loop with anchors resizing the page

        let minHeight = pageMinHeight;
        const pagePropertiesItem = _.get(serializedComp, ['propertiesItem', isMobileView() ? 'mobile' : 'desktop']);

        if (pagePropertiesItem && pagePropertiesItem.minHeight) {
            minHeight = pagePropertiesItem.minHeight;
        }

        measureMap.pageBottomByComponents[id] = pageBottomByComponents;
        measureMap.minHeight[id] = minHeight;
        measureMap.height[id] = Math.max(minHeight, pageBottomByComponents);
        measureMap.width[id] = nodesMap[id].offsetWidth;

        nodesMap.POPUPS_ROOT = $('#POPUPS_ROOT');
        if (serializedComp.dataItem.isPopup) {
            const dataItem = {background: getPageBackground(serializedComp, isMobileView())};
            const overlayStructureInfo = _.clone(serializedComp);

            overlayStructureInfo.id += POPUP_OVERLAY_SKIN_PART_ID;
            overlayStructureInfo.designDataItem = dataItem;

            measureMap.top[id] = 0;
            measureBalataPopupsOverlay(id, measureMap, nodesMap, overlayStructureInfo);
        }
    }

    function getPageBackground(structureInfo, isMobileView) {
        return _.get(structureInfo.dataItem, ['pageBackgrounds', getViewModeProperty(isMobileView), 'ref']);
    }

    function getViewModeProperty(isMobileView) {
        return isMobileView ? 'mobile' : 'desktop';
    }

    function measureBalataPopupsOverlay(id, measureMap, nodesMap, structureInfo) {
        screenWidthContainerLayout.measureScreenWidthContainer(id, measureMap, nodesMap);

        if (structureInfo.designDataItem.background) {
            balataLayout.measure(id, measureMap, nodesMap, structureInfo, {
                left: Math.max(0, 0.5 * (measureMap.clientWidth - measureMap.width.screen)),
                width: measureMap.width.screen,
                height: measureMap.innerHeight.screen
            });
        }
    }

    const COMP_TYPE = 'wixapps.integration.components.AppPage';

    layout.registerRequestToMeasureChildren(COMP_TYPE, [['inlineContent']]);
    layout.registerMeasureChildrenFirst(COMP_TYPE, true);
    layout.registerCustomMeasure(COMP_TYPE, measurePage);
    layout.registerRequestToMeasureDom(COMP_TYPE);
});
