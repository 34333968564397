define(['layout', 'wixappsLayout/specificComponents/wixappsLayoutUtils'], function (/** layout.layout */ layout, wixappsLayoutUtils) {
    'use strict';

    const {measureMatrixGallery, getChildrenIdToMeasure, patchMatrixGallery} = layout.specificComponents.matrixGalleryLayout;
    wixappsLayoutUtils.registerRequestToMeasureDom('wysiwyg.viewer.components.MatrixGallery');
    wixappsLayoutUtils.registerCustomMeasure('wysiwyg.viewer.components.MatrixGallery', measureMatrixGallery);
    wixappsLayoutUtils.registerRequestToMeasureChildren('wysiwyg.viewer.components.MatrixGallery', getChildrenIdToMeasure);
    wixappsLayoutUtils.registerPatcher('wysiwyg.viewer.components.MatrixGallery', patchMatrixGallery);
    return {};
});
