define(['layout', 'wixappsLayout/specificComponents/wixappsLayoutUtils'], function (/** layout.layout */ layout, wixappsLayoutUtils) {
    'use strict';

    const {measureSlideShow, getChildrenIdToMeasure, patchSlideShow} = layout.specificComponents.slideShowLayout;

    wixappsLayoutUtils.registerRequestToMeasureDom('wysiwyg.viewer.components.SlideShowGallery');
    wixappsLayoutUtils.registerCustomMeasure('wysiwyg.viewer.components.SlideShowGallery', measureSlideShow);
    wixappsLayoutUtils.registerRequestToMeasureChildren('wysiwyg.viewer.components.SlideShowGallery', getChildrenIdToMeasure);
    wixappsLayoutUtils.registerPatcher('wysiwyg.viewer.components.SlideShowGallery', patchSlideShow);
    return {};
});
