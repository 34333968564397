define(['layout', 'wixappsLayout/specificComponents/wixappsLayoutUtils'], function (/** layout.layout */ layout, wixappsLayoutUtils) {
    'use strict';

    const {patchPaginatedGridGallery, getChildrenIdToMeasure, customMeasurePaginatedGridGalleryLayout} = layout.specificComponents.paginatedGridGalleryLayout;

    wixappsLayoutUtils.registerPatcher('wysiwyg.viewer.components.PaginatedGridGallery', patchPaginatedGridGallery);
    wixappsLayoutUtils.registerRequestToMeasureChildren('wysiwyg.viewer.components.PaginatedGridGallery', getChildrenIdToMeasure);
    wixappsLayoutUtils.registerCustomMeasure('wysiwyg.viewer.components.PaginatedGridGallery', customMeasurePaginatedGridGalleryLayout);

    return {};
});
