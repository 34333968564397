define(['lodash', 'layout', 'wixappsLayout/specificComponents/wixappsLayout'], function (_, /** layout.layout */ layout, wixappsLayout) {
    'use strict';

    function getAppPartDataId(dataItem) {
        return dataItem.appPartName ? dataItem.id : dataItem.dataItemRef.id;
    }

    function getAppPartNodeId(zoomNodeId, permaLinkDataItem) {
        const mediaZoomId = permaLinkDataItem.id;
        const appPartId = getAppPartDataId(permaLinkDataItem);
        return zoomNodeId + mediaZoomId + appPartId;
    }

    function patchAppPartInMobile(appPartId, patchers, measureMap) {
        const inlineContentId = `${appPartId}inlineContent`;
        const height = Math.max(measureMap.height[appPartId], measureMap.height.screen);

        patchers.css(inlineContentId, {
            height
        });
    }
    function patchAppPartZoomInMobile(id, appPartId, patchers, measureMap) {
        const height = Math.max(measureMap.height[appPartId], measureMap.height.screen);

        patchers.css(id, {
            height
        });
    }

    function isApplicationExists(data, getClientSpecMapEntry) {
        const appId = _.has(data, 'dataItemRef') ? data.dataItemRef.appInnerID : data.appInnerID;
        return !!getClientSpecMapEntry(appId);
    }

    layout.registerRequestToMeasureChildren('wixapps.integration.components.AppPartZoom', function (id, nodesMap, structureInfo, siteData) {
        if (!isApplicationExists(structureInfo.dataItem, siteData.getClientSpecMapEntry)) {
            return null;
        }
        const appPartId = getAppPartDataId(structureInfo.dataItem);
        const mediaZoomId = structureInfo.dataItem.id;
        return [[mediaZoomId, appPartId], [mediaZoomId, appPartId, wixappsLayout.inlineContentId]];
    });

    layout.registerCustomMeasure('wixapps.integration.components.AppPartZoom', function (id, measureMap, nodesMap, structureInfo, {getClientSpecMapEntry}) {
        if (!isApplicationExists(structureInfo.dataItem, getClientSpecMapEntry)) {
            return null;
        }
        const appPartId = getAppPartNodeId(id, structureInfo.dataItem);
        wixappsLayout.appPartMeasureFunction(appPartId, measureMap, nodesMap);
        measureMap.custom[id] = {
            marginTop: Math.max((measureMap.height.screen - measureMap.height[appPartId]) / 2, 0),
            height: measureMap.height[appPartId]
        };
    });

    layout.registerPatcher('wixapps.integration.components.AppPartZoom', function (id, patchers, measureMap, structureInfo, siteData) {
        if (!isApplicationExists(structureInfo.dataItem, siteData.getClientSpecMapEntry)) {
            return;
        }
        //structureInfo is not in use so we are lazy :)
        const appPartNodeId = getAppPartNodeId(id, structureInfo.dataItem);

        if (siteData.isMobileView()) {
            patchAppPartZoomInMobile(id, appPartNodeId, patchers, measureMap);
            patchAppPartInMobile(appPartNodeId, patchers, measureMap);
        }
    });


    layout.registerLayoutInnerCompsFirst('wixapps.integration.components.AppPartZoom', function (structureInfo, zoomNode, measureMap, nodesMap, siteData) {
        if (!isApplicationExists(structureInfo.dataItem, siteData.getClientSpecMapEntry)) {
            return null;
        }
        const appPartNodeId = getAppPartNodeId(structureInfo.id, structureInfo.dataItem);
        const fakeStructure = {
            id: appPartNodeId
        };
        const appPartNode = window.document.getElementById(appPartNodeId);

        const changedCompsMap = {};
        changedCompsMap[structureInfo.id] = true;

        return {
            needsAdditionalInnerLayout: wixappsLayout.preMeasureProxies(fakeStructure, appPartNode, measureMap, nodesMap, siteData),
            changedCompsMap
        };
    }, function (structureInfo, measureMap, patchers, nodesMap, siteData) {
        if (!isApplicationExists(structureInfo.dataItem, siteData.getClientSpecMapEntry)) {
            return null;
        }
        const appPartNodeId = getAppPartNodeId(structureInfo.id, structureInfo.dataItem);
        const fakeStructure = {
            id: appPartNodeId
        };

        return wixappsLayout.postPatchProxies(fakeStructure, measureMap, patchers, nodesMap, siteData);
    });
});
