define(['layout', 'wixappsLayout/specificComponents/wixappsLayoutUtils'], function (/** layout.layout */ layout, wixappsLayoutUtils) {
    'use strict';

    const {getChildrenIdToMeasure, measureSliderGalleryLayout, patchSliderGalleryLayout} = layout.specificComponents.sliderGalleryLayout;

    wixappsLayoutUtils.registerRequestToMeasureChildren('wysiwyg.viewer.components.SliderGallery', getChildrenIdToMeasure);
    wixappsLayoutUtils.registerCustomMeasure('wysiwyg.viewer.components.SliderGallery', measureSliderGalleryLayout);
    wixappsLayoutUtils.registerPatcher('wysiwyg.viewer.components.SliderGallery', patchSliderGalleryLayout);

    return {};
});
