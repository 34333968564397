define(['zepto', 'coreUtils'], function ($, /** layout.proxyLayoutRegistrar */ coreUtils) {
    'use strict';

    const proxyLayoutRegistrar = coreUtils.proxyLayoutRegistrar;
    function measureSliderGallery(proxyNode, siteData, measureMap) {
        const $node = $(proxyNode);
        const compNode = proxyNode.firstChild;
        const compId = compNode.id;
        
        const propertiesItem = {
            aspectRatio: $node.attr('data-aspect-ratio'),
            imageMode: $node.attr('data-image-mode')
        };

        measureMap.width[compId] = proxyNode.offsetWidth;
        measureMap.height[compId] = proxyNode.offsetHeight;

        return {
            comp: {
                compType: 'wysiwyg.viewer.components.SliderGallery',
                compId,
                structureInfo: {
                    dataItem: null,
                    propertiesItem,
                    layout: null,
                    styleItem: null
                }
            },
            domManipulations: []
        };
    }


    proxyLayoutRegistrar.registerCustomMeasure('SliderGallery', measureSliderGallery);
});
