define([
    'layout',
    'wixappsLayout/specificComponents/wixappsLayoutUtils'
], function (layout, wixappsLayoutUtils) {
    'use strict';

    const measureNodeImage = layout.rootLayoutUtils.measureNodeImage;
    wixappsLayoutUtils.registerCustomMeasure('core.components.Image', measureNodeImage);

    return {
        measureNodeImage
    };
});
