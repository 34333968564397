define([
    'wixappsLayout/specificComponents/wixappsLayoutUtils'
], function (wixappsLayoutUtils) {
    'use strict';

    const MAX_HEIGHT = 2000;

    function measureVerticalLine(id, measureMap, nodesMap) {
        measureMap.height[id] = Math.min(measureMap.height[id], MAX_HEIGHT);
        measureMap.width[id] = parseInt(nodesMap[id].style.width, 10);
        measureMap.left[id] = parseInt(nodesMap[id].style.left, 10);
    }

    wixappsLayoutUtils.registerCustomMeasure('wysiwyg.viewer.components.VerticalLine', measureVerticalLine);
});
