define(['wixappsLayout/specificComponents/wixappsLayoutUtils'], function (wixappsLayoutUtils) {
    'use strict';

    const DIMENSIONS = {
        VIMEO: {
            WIDTH: 100,
            HEIGHT: 100
        },
        DAILYMOTION: {
            WIDTH: 100,
            HEIGHT: 100
        },
        FACEBOOK: {
            WIDTH: 100,
            HEIGHT: 100
        },
        YOUTUBE: {
            WIDTH: 200,
            HEIGHT: 200
        }
    };

    const FALLBACK = {
        WIDTH: 10,
        HEIGHT: 10
    };

    function measureVideo(id, measureMap, nodesMap, structureInfo) {
        const dimensions = DIMENSIONS[structureInfo.dataItem.videoType] || FALLBACK;
        measureMap.minWidth[id] = dimensions.WIDTH;
        measureMap.minHeight[id] = dimensions.HEIGHT;
        measureMap.width[id] = Math.max(measureMap.width[id], dimensions.WIDTH);
        measureMap.height[id] = Math.max(measureMap.height[id], dimensions.HEIGHT);
        //store y position in measure. if players goes to fullscrenn we must store the original top
        //todo: add management to fullscreen cases
        measureMap.top[id] = structureInfo.layout.y;
    }

    wixappsLayoutUtils.registerCustomMeasure('wysiwyg.viewer.components.Video', measureVideo);
});
