define(['wixappsLayout/specificComponents/wixappsLayoutUtils', 'zepto', 'lodash'], function (/** layout.layout */ wixappsLayoutUtils, $, _) {
    'use strict';


    function getDomNode() {
        const path = _.toArray(arguments);
        return window.document.getElementById(path.join(''));
    }

    function measureMediaRichText(id, measureMap, nodesMap, stubStructureInfo, siteData) {
        function measureMediaRichTextInnerComponent(structureInfo) {
            wixappsLayoutUtils.measureMediaRichTextInnerComponent(structureInfo, getDomNode, measureMap, nodesMap, siteData, structureInfo.structure);
        }

        const node = $(nodesMap[id]);
        measureMap.custom[id] = {
            prevWidth: node.data('width')
        };


        const mediaRichTextInnerComponents = siteData.getMediaRichTextInnerComponents(id);
        const structureInfos = _.mapValues(mediaRichTextInnerComponents, 'structureInfo');
        _.forEach(structureInfos, measureMediaRichTextInnerComponent);
    }

    function patchMediaRichText(id, patchers, measureMap, MRTstructureInfo, siteData) {
        function patchMediaRichTextInnerComponent(structureInfo) {
            wixappsLayoutUtils.patchWithoutPositioning(structureInfo, patchers, measureMap, siteData);
        }

        const prevWidth = measureMap.custom[id].prevWidth;
        const currentWidth = parseInt(measureMap.width[id], 10);

        const mediaRichTextInnerComponents = siteData.getMediaRichTextInnerComponents(id);
        const structureInfos = _.mapValues(mediaRichTextInnerComponents, 'structureInfo');

        _.forEach(structureInfos, patchMediaRichTextInnerComponent);

        if (!prevWidth || parseInt(prevWidth, 10) !== currentWidth) {
            patchers.data(id, {'width': currentWidth});
            return true;
        }
        patchers.css(id, {
            height: ''
        });


        return false;
    }

    wixappsLayoutUtils.registerRequestToMeasureDom('wysiwyg.viewer.components.MediaRichText');
    wixappsLayoutUtils.registerCustomMeasure('wysiwyg.viewer.components.MediaRichText', measureMediaRichText);
    wixappsLayoutUtils.registerPatcher('wysiwyg.viewer.components.MediaRichText', patchMediaRichText);
});
