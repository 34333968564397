define([
    'layout',
    'wixappsLayout/specificComponents/wixappsSingleCompLayout'
], function (layout, wixappsSingleCompLayout) {
    'use strict';

    return {
        registerRequestToMeasureDom(className) {
            layout.singleCompLayout.registerRequestToMeasureDom(className);
        },
        registerRequestToMeasureChildren(className, pathArray) {
            wixappsSingleCompLayout.registerRequestToMeasureChildren(className, pathArray);
        },
        registerCustomMeasure(className, mapFix) {
            wixappsSingleCompLayout.registerCustomMeasure(className, mapFix);
        },
        registerPatcher(className, patcher) {
            wixappsSingleCompLayout.registerPatcher(className, patcher);
        },
        measureMediaRichTextInnerComponent(structureInfo, getDomNodeFunc, measureMap, nodesMap, siteData, structure) {
            wixappsSingleCompLayout.measureComponentChildren(structureInfo, getDomNodeFunc, measureMap, nodesMap, siteData);
            wixappsSingleCompLayout.measureComponent(structure, structureInfo, getDomNodeFunc, measureMap, nodesMap, siteData);
        },
        patchWithoutPositioning: wixappsSingleCompLayout.patchWithoutPositioning
    };
});
