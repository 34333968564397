define([
    'wixappsLayout/specificComponents/oldSiteButtonLayout',
    'wixappsLayout/specificComponents/imageLayout',
    'wixappsLayout/specificComponents/wixappsLayout',
    'wixappsLayout/specificComponents/appPartZoomLayout',
    'wixappsLayout/specificComponents/videoLayout',
    'wixappsLayout/specificComponents/verticalLineLayout',
    'wixappsLayout/specificComponents/mediaRichTextLayout',
    'wixappsLayout/specificComponents/legacyLayout',

    // Dependent specificComponent
    'wixappsLayout/specificComponents/matrixGalleryLayout',
    'wixappsLayout/specificComponents/paginatedGridGalleryLayout',
    'wixappsLayout/specificComponents/richtextLayout',
    'wixappsLayout/specificComponents/slideShowLayout',
    'wixappsLayout/specificComponents/sliderGalleryLayout',
    'wixappsLayout/specificComponents/wPhotoLayout',


    'wixappsLayout/proxies/proxyLayout/paginatedColumnGalleryProxyLayout',
    'wixappsLayout/proxies/proxyLayout/galleryProxyLayout',
    'wixappsLayout/proxies/proxyLayout/imageProxyLayout',
    'wixappsLayout/proxies/proxyLayout/buttonProxyLayout',
    'wixappsLayout/proxies/proxyLayout/mediaLabelProxyLayout',
    'wixappsLayout/proxies/proxyLayout/sliderGalleryProxyLayout'
], function () {
    'use strict';
});
