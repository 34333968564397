define(['layout', 'wixappsLayout/specificComponents/wixappsLayoutUtils'], function (/** layout.layout */ layout, wixappsLayoutUtils) {
    'use strict';

    const {richTextCompName, measureWRichText, patchWRichText} = layout.specificComponents.richtextLayout;
    wixappsLayoutUtils.registerRequestToMeasureDom(richTextCompName);
    wixappsLayoutUtils.registerCustomMeasure(richTextCompName, measureWRichText);
    wixappsLayoutUtils.registerPatcher(richTextCompName, patchWRichText);

    return {};
});
